<template>
  <div class="w-full h-full">
    <h1 class="text-xl text-left font-extrabold pl-4 mt-4">
      Roles & Privileges
    </h1>
    <div v-if="!showError">
      <div class="mx-3 mt-6">
        <Card class="mt-6 p-5">
          <div class="flex mb-6" style="justify-content: flex-end">
            <Button class="bg-dynamicBackBtn text-white"
              @click="handleNew"
            >
              New Role
            </Button>
          </div>
          <template v-if="roles.length > 0" v-slot:footer>
            <card-footer
              @sortType="handleSort($event)"
              @searchResult="handleSearch($event)"
            />
          </template>
        </Card>
      </div>
      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else class="w-full h-full p-3">
        <sTable
          :headers="roleHeader"
          v-if="roles.length > 0"
          :items="roles"
          style="width: 100%"
          class="w-full"
          :has-checkbox="false"
          aria-label="role table"
          has-number
          :loading="loading"
        >
          <template v-slot:item="{ item }">
            <span v-if="item.isDefault">
              <div class="flex">
                <div
                  class="p-1"
                  :class="item.data.isDefault ? 'default' : 'custom'"
                >
                  {{ item.data.isDefault ? "Default" : "Custom" }}
                </div>
                <button
                  class="text-sm mt-1 text-flame font-semibold"
                  @click="handleView(item.data)"
                  style="width: 79px; height: 20px"
                >
                  View
                </button>
              </div>
            </span>
            <span v-else-if="item.usersCount">
              {{
                `${item.data.usersCount} ${
                  Number(item.data.usersCount) > 1 ? "Users" : "User"
                }`
              }}
            </span>
            <span v-else-if="item.id">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                <div
                  v-if="!item.data.isDefault"
                  style="width: 116px; height: 81px"
                  class="py-3 px-2"
                >
                  <div
                    class="flex py-1 px-3 more h-8 cursor-pointer"
                    @click="handleEdit(item.data)"
                  >
                    <icon
                      icon-name="edit"
                      class-name="text-blueCrayola mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Edit</p>
                  </div>
                  <div
                    class="flex px-3 py-1 more h-8 cursor-pointer"
                    @click="confirmDeleteRole(item.data.id)"
                  >
                    <icon
                      icon-name="delete"
                      class-name="text-desire mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">Delete</p>
                  </div>
                </div>
                <div v-else style="width: 116px" class="py-3 px-2">
                  <div
                    class="flex py-1 px-3 more h-8 cursor-pointer"
                    @click="handleView(item.data)"
                  >
                    <icon
                      icon-name="icon-eye"
                      class-name="text-blueCrayola mr-2"
                      size="xsm"
                    />
                    <p class="pt-1">View</p>
                  </div>
                </div>
              </Menu>
            </span>
          </template>
        </sTable>
        <div
          class="w-full h-full mt-10 flex flex-col"
          style="align-items: center"
          v-else
        >
          <div class="w-5/12 h-60 mt-5 flex" style="justify-content: center">
            <icon icon-name="payScheduleEmptyImg" size="l" style="width: 300px" />
          </div>
          <div class="w-8/12 h-auto text-center">
            <p class="mb-5 tracking-wide">
              You have not set up any access privileges yet. Your account was
              created with a default "Root Admin" role. The Root Admin role is
              reserved for the account owner/manager and is not restricted in
              any way. You can not assign this role to another user, but you can
              transfer it. You will cease to be the root admin as soon as you
              transfer. <br>
              <br>
              You can create other role types and assign app-specific
              privileges to them. You can always return at any time to edit the
              privileges of any created roles. Click New Role above to start creating roles.
            </p>
          </div>
        </div>
      </div>
    </div>
    <ErrorPage v-else />
    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Role?
          </p>
          <div class="flex">
            <Button class="bg-dynamicBackBtn text-white"
              @click="deleteRole"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
    <actions
      :open-modal="openActionModal"
      :role-id="roleId"
      :is-default="isDefault"
      :user-count="userCount"
      @close="openActionModal = false"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Loader from "@/components/Loader";
import Icon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";
import ErrorPage from "@/modules/Admin/error403";
import Actions from "./actions";
import MySubscription from "./mySubcription";

export default {
  name: "RolesAndPrivileges",
  components: {
    Button,
    Card,
    STable,
    CardFooter,
    Loader,
    ErrorPage,
    Icon,
    RightSideBar,
    Actions,
    Menu,
    MySubscription,
    Modal,
  },
  data() {
    return {
      roles: [],
      openActionModal: false,
      editRole: false,
      kpaSummary: {},
      dialogDel: false,
      isDefault: false,
      userCount: 0,
      roleId: "",
      searchWord: null,
      showError: false,
      sortType: null,
      loading: true,
      roleHeader: [
        { title: "ROLE NAME", value: "name", width: "20" },
        { title: "DESCRIPTION", value: "description", width: "35" },
        { title: "ACTIONS/PRIVILEGES", value: "isDefault", width: "20" },
        { title: "ASSIGNED TO", value: "usersCount", width: "20" },
        { title: "", value: "id", image: true },
      ],
    };
  },

  methods: {
    queryUpdate() {
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      this.getRole(`${sort}${search}`);
    },
    async handleNew() {
      try {
        await this.$handlePrivilege("rolesandPrivilegesHR", "createNewRole");
        this.$router.push({ name: "Create role" });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handleEdit(item) {
      try {
        await this.$handlePrivilege("rolesandPrivilegesHR", "editRole");
        this.$router.push({
          name: "Edit role",
          params: { id: item.id },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async getRole(query) {
      this.loading = true;
      try {
        await this.$handlePrivilege(
          "rolesandPrivilegesHR",
          "accessRolesAndPrivilegesSettiings"
        );
        this.$_NewGetOrgRoles(query)
          .then((result) => {
            this.roles = result.data.roles;
            this.showError = false;
            this.loading = false;
          })
          .catch((err) => {
            throw new Error(err.message);
          });
      } catch (error) {
        this.loading = false;
        this.showError = true;
      }
    },
    handleSearch(searchWord) {
      this.loading = true;
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.loading = true;
      const changeSortType = sortType.split(":");
      let result;
      if (changeSortType[0] === "createdAt") {
        result = sortType;
      } else {
        result = `name:${changeSortType[1]}`;
      }
      this.sortType = result;
      this.queryUpdate();
    },
    async handleView(item) {
      try {
        await this.$handlePrivilege("rolesandPrivilegesHR", "viewPrivileges");
        this.roleId = item.id;
        this.userCount = item.usersCount;
        this.isDefault = item.isDefault;
        this.openActionModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async confirmDeleteRole(id) {
      try {
        await this.$handlePrivilege("rolesandPrivilegesHR", "deleteRole");
        this.dialogDel = true;
        this.roleId = id;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async deleteRole() {
      try {
        await this.$_delOrgRoles(this.roleId);
          this.$toasted.success("Role deleted successfully", {
            duration: 3000,
          });
          this.dialogDel = false;
          this.getRole();
      } catch (error) {
        this.dialogDel = false;
        this.$toasted.error(error.response.data.message, {
          duration: 3000,
        });
      }
    },
  },
  mounted() {
    this.getRole();
  },
};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}

.default {
  background: rgba(135, 142, 153, 0.15);
  border-radius: 5px;
}
.custom {
  background: rgba(33, 118, 255, 0.15);
  border-radius: 5px;
}
.more:hover {
  background: #eeeeee;
}
</style>